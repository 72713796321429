<template>
  <NuxtImg
    v-show="!hasError"
    :placeholder="placeholder"
    :src="src"
    :alt="alt"
    :quality="quality"
    draggable="false"
    v-bind="$attrs"
    class="w-full flex-shrink-0 transition-all duration-300"
  />
  <BaseImagePlaceholder v-if="hasError || !src" title="no image" />
</template>

<script setup lang="ts">
import { withDefaults, ref, onMounted } from 'vue';
import BaseImagePlaceholder from '~/components/BaseImagePlaceholder.vue';

defineOptions({
  inheritAttrs: false,
});

interface Props {
  src?: string
  alt?: string
  placeholder?: boolean
  quality?: string | number
}

const props = withDefaults(defineProps<Props>(), {
  src: undefined,
  alt: undefined,
  placeholder: true,
  quality: 100,
});

const hasError = ref(false);

onMounted(() => {
  checkImage(props.src as string);
});

function onError () {
  hasError.value = true;
}

function onLoad () {
  hasError.value = false;
}

function checkImage (url: string) {
  const img = new Image();
  img.onload = function () {
    onLoad();
  };
  img.onerror = function () {
    onError();
  };
  img.src = url;
}
</script>
